import { useState } from "react";
import { useFileHelper } from "../helpers/fileHelper";
import { v4 as uuid } from "uuid";
import { FileType } from "../enums/enums";

interface IProps{
    buttonLabel: string;
    fileType: FileType;
    multiple?: boolean;
    uploadDisabled?: boolean;
    validateFiles?: (model: File[]) => Promise<boolean>;
    onUpload: (model: File[]) => Promise<void>;
};

const FileInput = (props: IProps) => {
    const [fileModels, setFileModels] = useState<File[]>();
    const fileHelper = useFileHelper();
    const [fileInputKey, setFileInputKey] = useState(uuid());

    const onFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = fileHelper.getBlobFiles(event);
        setFileModels(files);
    };

    const onUploadClick = async () => {
        if (fileModels){
            if (props.validateFiles && await props.validateFiles(fileModels) !== true){
                return;
            }

            await props.onUpload(fileModels).then(_ => {
                window.alert('Nahrávání dokončeno!')
            });
            setFileModels(undefined);
            setFileInputKey(uuid());
        }
    };

    const isUploadDisabled = !fileModels || (props.uploadDisabled != null && props.uploadDisabled === true);

    return (
        <>
            <input type='file' multiple={props.multiple ?? false} onChange={onFileSelected} key={fileInputKey}/>
            <button onClick={onUploadClick} disabled={isUploadDisabled}>{props.buttonLabel}</button>
        </>
    );
};

export { FileInput };