import { useState } from "react";
import { uploadBytes } from "@firebase/storage";
import React from "react";
import { push, set, ThenableReference } from "@firebase/database";
import { IGalleryFolder, IGalleryFolderDetail, useGalleryService } from "../../../dataServices/gallery/galleryService";
import { getDatabaseReference, getStorage } from "../../../firebase";
import { urls } from "../../../urls";
import { FileInput } from "../../../components/FileInput";
import { FileType } from "../../../enums/enums";

interface UploadingProgress {
    isInProgress: boolean;
    totalImages: number;
    actualImage: number;
}

const GalleryManagement : React.FunctionComponent = () => {
    const galleryService = useGalleryService();
    const [folderName, setFolderName] = useState<string>();
    const [uploadingProgress, setUploadingProgress] = useState<UploadingProgress>( {isInProgress: false, actualImage:0, totalImages: 0} );
    const [galleryFolders, setGalleryFolders] = useState<Array<IGalleryFolder>>([]);
    const [isFolderDetailVisible, setIsFolderDetailVisible] = useState<boolean>(false);
    const [actualSelectedFolder, setActualSelectedFolder] = useState<IGalleryFolderDetail>();

    const uploadFile = async (imageFiles: File[]) : Promise<void> => {
        setUploadingProgress({ isInProgress: true, totalImages: imageFiles.length, actualImage: 0 });
        const uploadPromises = imageFiles.map(async (file, index) => {
            const storage = getStorage(`${urls.Gallery.Storage}/${actualSelectedFolder?.folderDetailId}/${file.name}`);
            await uploadBytes(storage, file).then(_ => {
                setUploadingProgress({totalImages: imageFiles.length, isInProgress: true, actualImage: index});
            });
        });

        await Promise.all(uploadPromises).then(async _ => {
            setUploadingProgress({ ...uploadingProgress, isInProgress: false });
            loadFolderDetail(actualSelectedFolder!.folderId);
            window.alert('Nahrávání dokončeno!');
        });
    }

    const loadImageFolders = async () => {
        const folders = await galleryService.getFolderInfo();
        setGalleryFolders(folders);
    }

    const validateFolderName = (folderName: string) : boolean => {
        if (folderName == null || folderName === '') {
            window.alert('Vyplňte název alba');
            return false;
        }

        return true;
    }

    const getMaxFolderIndex = () : number => {
        if (galleryFolders.length === 0) {
            return 1;
        }

        return galleryFolders.length;
    }

    const uploadFolderModel = async (folderName: string) : Promise<ThenableReference> => {
        const imageFolderModel = {
            index: getMaxFolderIndex() + 1,
            name: folderName,
        };

        const dbRef = getDatabaseReference(urls.Gallery.FolderInfo);
        const dataRef = push(dbRef);
        await set(dataRef, imageFolderModel).then(_ => {
            loadImageFolders();
            setFolderName("");
        });

        return dataRef;
    }

    const uploadFolderDetailModel = async (folderId?: string | null) => {
        const imageFolderDetailModel = {
            folderId: folderId ?? '',
            folderName: folderName,
            images: []
        }

        const dbFolderDetailRef = getDatabaseReference(urls.Gallery.FolderDetail);
        const dataFolderDetailRef = push(dbFolderDetailRef);
        await set(dataFolderDetailRef, imageFolderDetailModel);
    }

    const createNewFolder = async (folderName: string) => {
        if (!validateFolderName(folderName)) {
            return;
        }

        const folderDataRef = await uploadFolderModel(folderName);
        await uploadFolderDetailModel(folderDataRef.key);
    }

    const loadFolderDetail = async (folderId: string) => {
        const folderDetail = await galleryService.getGalleryDetail(folderId);
        if (folderDetail != null) {
            setActualSelectedFolder(folderDetail);
            setIsFolderDetailVisible(true);
        }
    }

    React.useEffect(() => {
        loadImageFolders();
    }, []);

    return (
        <>
            {isFolderDetailVisible === false && (
                <>
                    <h2>Seznam alb</h2>
                    {galleryFolders.length > 0 
                        ? galleryFolders.map(folder => <><span className="link mb-2" onClick={() => loadFolderDetail(folder.Id)}>{folder.Label}</span><br/></>)
                        : <div>Zatím nejsou vytvořeny žádná alba</div>}
                    <br/>
                    <h2>Vytvoření nového alba</h2>
                    <div>
                        <div>Název alba</div>
                        <input type="text" value={folderName} onChange={e => setFolderName(e.target.value)} />
                        <button onClick={() => createNewFolder(folderName ?? '')}>Vytvořit album</button>
                    </div>
                </>
            )}
            {isFolderDetailVisible && (
                <>
                    <h3>{actualSelectedFolder ? actualSelectedFolder.folderName : ''}</h3>
                    <br />
                    <div>
                        {actualSelectedFolder?.images.map(image => (
                            <>
                                <a href={image.thumbnailUrl} target="_blank" className="link mb-2">{image.name}</a>
                                <br/>
                            </>
                        ))}
                    </div>
                    <div>
                        <FileInput 
                            buttonLabel="Nahrát obrázky"
                            fileType={FileType.Blob}
                            multiple={true}
                            onUpload={uploadFile} />
                    </div>
                    {uploadingProgress.isInProgress === true && (
                        <div>{`Nahráno ${uploadingProgress.actualImage} fotek z ${uploadingProgress.totalImages}`}</div>
                    )}
                    <br/>
                    <button onClick={() => setIsFolderDetailVisible(false)}>Zpět</button>
                </>
            )}
        </>
    )
};

export { GalleryManagement };