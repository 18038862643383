import { child, get, set } from "@firebase/database";
import { getDatabaseReference } from "../../firebase";
import { urls } from "../../urls";
import { useFileHelper } from "../../helpers/fileHelper";

export interface IAnnoucmentSchema{
    Annoucment: Array<IAnnoucment>;
};

export interface IAnnoucment{
    Id: number;
    Content: string;
};

const useAnnoucmentService = () => {
    const dbRef = getDatabaseReference(urls.Annoucment.Index);
    const fileHelper = useFileHelper();

    const getAnnoucments = async() : Promise<Array<IAnnoucment>> => {
        const snapshot = await get(child(dbRef, '/'));
        const result = snapshot.val() as Array<IAnnoucment>;
        result.sort((a, b) => a.Id - b.Id);
        return result;
    };

    const addAnnoucments = async(files: File[]) => {
        const model = await fileHelper.readFileAsJson(files[0]) as IAnnoucmentSchema;
        await set(dbRef, model.Annoucment);
    };

    const validateAnnoucmentFile = async (inputFiles: File[]) : Promise<boolean> => {
        if (inputFiles.length != 1) {
            return false;
        }

        const file = inputFiles[0];
        const jsonFile = await fileHelper.readFileAsJson(file);
        const validationResult = validateAnnoucmentJson(jsonFile as IAnnoucmentSchema);
        return validationResult;
    }

    const validateAnnoucmentJson = (model: IAnnoucmentSchema) : boolean => {
        try{
            model.Annoucment.forEach(annoucment => {
                if(annoucment.Content === undefined || annoucment.Id === undefined){
                    throw new Error("Chybí některá z požadovaných hodnot");
                };
            });
            return true;
        }catch(e: any){
            window.alert("Soubor JSON není ve správném formátu.\n" +
                "Stáhněte si ukázkový JSON soubor z odkazu v  nápovědě.\n" +
                `Chybová hláška: ${e.message}`);
            return false;
        }
    }

    return { getAnnoucments, addAnnoucments, validateAnnoucmentFile };
};

export { useAnnoucmentService };