import { child, get, set } from "@firebase/database";
import { getDatabaseReference } from "../../firebase";
import { urls } from "../../urls";
import { useFileHelper } from "../../helpers/fileHelper";

export interface IMassDaySchema {
    MassDay: Array<IMassDay>;
};

export interface IMass{
    Place: string;
    Time: string;
}

export interface IMassDay
{
    MassDayId: number;
    Day: string;
    Description: string;
    Masses: Array<IMass>;
}

const useMassDayService = () => {
    const dbRef = getDatabaseReference(urls.MassDay.Index);
    const fileHelper = useFileHelper();

    const getMassDays = async() : Promise<Array<IMassDay>> => {
        const snapshot = await get(child(dbRef, '/'));
        const result = await snapshot.val() as Array<IMassDay>;
        result.sort((a, b) => a.MassDayId - b.MassDayId);
        return result;
    };

    const uploadMassDays = async(model: File[]): Promise<void> => {
        const massDaySchema = await fileHelper.readFileAsJson(model[0]) as IMassDaySchema;
        await set(dbRef, massDaySchema.MassDay);
    };

    const validateMassDayFile = async (inputFiles: File[]) : Promise<boolean> => {
        if (inputFiles.length != 1){
            return false;
        }

        const file = inputFiles[0];
        const jsonFile = await fileHelper.readFileAsJson<IMassDaySchema>(file);
        return validateMassDayJson(jsonFile);
    }

    const validateMassDayJson = (massDaySchema: IMassDaySchema) : boolean => {
        try {
            massDaySchema.MassDay.forEach(massDay => {
                if (massDay.Day === undefined || massDay.MassDayId === undefined || massDay.Description === undefined) {
                    throw new Error("Chybí některá z požadovaných hodnot");
                };
                massDay.Masses.forEach(mass => {
                    if (mass.Place === undefined || mass.Time === undefined) {
                        throw new Error("Chybí některá z požadovaných hodnot");
                    }
                });
            });
            return true;
        } catch (e: any) {
            window.alert("Soubor JSON není ve správném formátu.\n" +
                "Stáhněte si ukázkový JSON soubor z odkazu v  nápovědě.\n" +
                `Chybová hláška: ${e.message}`);
            return false;
        }
    }

    return { getMassDays, uploadMassDays, validateMassDayFile };
};

export { useMassDayService };