import { child, DataSnapshot, equalTo, get, orderByChild, query, set } from "@firebase/database";
import { IOrderable } from "../../core/interfaces/IOrderable";
import { getDatabaseReference } from "../../firebase";
import { urls } from "../../urls";

export interface IGalleryFolder extends IOrderable {
    Id: string;
    Index: number;
    Label: string;
}

export interface IGalleryFolderDetail {
    folderDetailId: string;
    folderId: string;
    folderName: string;
    images: Array<IImageInfo>;
}

export interface IImageInfo {
    name: string;
    url: string;
    thumbnailUrl: string;
}

const useGalleryService = () => {

    const getFolderInfo = async() : Promise<Array<IGalleryFolder>> => {
        const dbRef = getDatabaseReference(urls.Gallery.FolderInfo);
        const snapshot = await get(child(dbRef, '/'));
        const data = snapshot.val();
        if (data) {
            return mapFolderInfo(data);
        }
        return [];
    };

    const getGalleryDetail = async(folderId: string) : Promise<IGalleryFolderDetail | null> => {
        try {
            const dbRef = getDatabaseReference(`${urls.Gallery.FolderDetail}`);
            const folderQuery = query(dbRef, orderByChild("folderId"), equalTo(folderId));
            const snapShot = await get(folderQuery);
            if (snapShot.exists()) {
                const data = snapShot.val();
                const folderDetail = Object.keys(data).map(key => (
                    {
                        folderDetailId: key,
                        folderName: data[key].folderName,
                        folderId: data[key].folderId,
                        images: data[key].FolderItems ? Object.keys(data[key].FolderItems).map(folderItemKey => data[key].FolderItems[folderItemKey]) : [],
                    }
                ))[0];
                return folderDetail;
            }
    
            return null;
        } 
        catch (exception) {
            window.alert('Nepodařilo se načíst složku galerie');
            return null;
        }
    }

    const mapFolderInfo = (data: any) : IGalleryFolder[] => {
        const result = Object.keys(data).map(key => ({
            Id: key,
            Index: data[key].index,
            Label: data[key].name,
        })) as IGalleryFolder[];

        return result;
    }

    const setGalleryInfo = async (galleryInfo: Array<IGalleryFolder>) : Promise<void> => {
        const dbRef = getDatabaseReference(urls.Gallery.FolderInfo);
        await set(dbRef, galleryInfo);
    }

    return { getFolderInfo, getGalleryDetail, setGalleryInfo };
};

export { useGalleryService  };