import { useAnnoucmentService } from "../../../dataServices/annoucment/annoucmentService";
import { JsonSchemaHint } from "../../Shared/JsonSchemaHint";
import { FileType } from "../../../enums/enums";
import { FileInput } from "../../../components/FileInput";

const AnnoucmentManagement : React.FunctionComponent = () => {
    const annoucmentService = useAnnoucmentService();
    
    return (
        <>
            <h2>Správa ohlášek a oznámení</h2>
            <JsonSchemaHint
                downloadFileName='AnnoucmentSchema.json'
                jsonSchemaUrl='https://firebasestorage.googleapis.com/v0/b/hosteradiceparish.appspot.com/o/JsonSchemaExamples%2FannoucmentSchema.json?alt=media&token=63ea0563-b89a-43e3-89f5-95f61b9a5400'
            />
            <FileInput 
                buttonLabel="Nahrát aktuality ze souboru"
                fileType={FileType.Json}
                onUpload={annoucmentService.addAnnoucments}
                validateFiles={annoucmentService.validateAnnoucmentFile} />
        </>
    );
};

export { AnnoucmentManagement };