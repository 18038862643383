import { useParams } from "react-router-dom";
import { DataFetcher } from "../../dataServices/core/DataFetcher";
import { IGalleryFolderDetail, useGalleryService } from "../../dataServices/gallery/galleryService";

interface IGalleryDetailParams{
    id: string;
}

const GalleryDetail : React.FunctionComponent = () => {
    const params : IGalleryDetailParams = useParams();
    const folderHash = decodeURIComponent(params.id);
    const gallery = useGalleryService();
    
    return (
        <DataFetcher dataMethod={() => gallery.getGalleryDetail(folderHash)}>
            {(data: IGalleryFolderDetail) => (
                <>
                    <h3>{data.folderName}</h3>
                    <div className="container">
                        <div className="row">
                            {data.images.map((image, index) => (
                                <div key={index} className="col-sm-12 col-md-4 col-lg-2">
                                    <a href={image.url} data-lightbox='roadtrip' data-title={image.name}>
                                        <img alt={image.name} src={image.thumbnailUrl} className='img-thumbnail img-fluid' />
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}
        </DataFetcher>
    )
}

export { GalleryDetail };